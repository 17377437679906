import React, { useCallback, useEffect, useState } from "react";
import { Line, LineConfig } from "@ant-design/charts";

import { StatisticAPI } from "services/statistics.service";
import { useFilters } from "hooks/filters.hooks";
import { useRegion } from "hooks/region.hooks";

import { ChartSubtitle, ChartTitle, ChartWrapper } from "./Charts.styled";

function DynamicVacancies() {
  const [filter] = useFilters();
  const region = useRegion();
  const [data, setData] = useState<any[]>([]);

  const asyncFetch = useCallback(async () => {
    try {
      // Получаем данные с сервера
      const response = await StatisticAPI.fetchVacancies({
        filters: filter,
        region_id: region?.res_id,
      });

      // Если фильтр включен, выводим дополнительную линию
      let data = response.resumecount.reduce<any[]>((acc, item) => {
        return acc.concat([
          {
            name: "Все вакансии",
            period: item.period,
            vacancies: item.filtered_vacancies,
          },
        ]);
      }, []);
      setData(data);
    } catch (error) {}
  }, [filter, region?.res_id]);

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  const config: LineConfig = {
    data,
    height: 300,
    xField: "period",
    yField: "vacancies",
    seriesField: "name",
    smooth: true,
    animation: {
      appear: {
        animation: "path-in",
        duration: 5000,
      },
    },
  };

  return (
    <ChartWrapper>
      <ChartTitle>Динамика вакансий</ChartTitle>
      <ChartSubtitle>Количество вакансий, шт.</ChartSubtitle>
      <Line {...config} />
    </ChartWrapper>
  );
}

export default DynamicVacancies;
