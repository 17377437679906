import React from "react";
import {
  ResponsiveContent,
  ResponsiveLayout,
  ResponsiveSidebar,
} from "common/ResponsiveLayout";

type SplitScreenProps = {
  children: React.ReactNode[];
};

const SplitScreen: React.FC<SplitScreenProps> = ({ children }) => {
  const [map, sidebar] = children;

  return (
    <ResponsiveLayout>
      <ResponsiveContent>{map}</ResponsiveContent>
      <ResponsiveSidebar>{sidebar}</ResponsiveSidebar>
    </ResponsiveLayout>
  );
};

export default React.memo(SplitScreen);
