import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { fetchCountryStatistics } from "store/statistics/statistics.reducer";
import { selectFieldsOptions } from "store/fields/fields.selector";
import { selectPositionsOptions } from "store/positions/positions.selector";
import { fetchPositions } from "store/positions/positions.reducer";
import { fetchFields } from "store/fields/fields.reducer";

import { useFilters } from "hooks/filters.hooks";
import { filterKey } from "util/filterKeys";

import FilterItem from "./FilterItem";
import { FilterWrapper } from "./Filter.styled";

function Filters() {
  const dispatch = useDispatch();
  const [filter, setFilter] = useFilters();

  useEffect(() => {
    dispatch(fetchCountryStatistics({ filters: filter }));
  }, [dispatch, filter]);

  const handleChange = useCallback(
    (key: string, value: number) => setFilter({ [key]: value }),
    [setFilter]
  );

  return (
    <FilterWrapper>
      {/* <FilterItem
        filterKey={filterKey.skill}
        value={filter.skill_id}
        placeholder="Технология/компетенция"
        onChange={handleChange}
        selector={selectSkillsOptions}
        action={fetchSkills}
      /> */}
      <FilterItem
        filterKey={filterKey.position}
        value={filter.special_id}
        placeholder="Должность/Профессия"
        onChange={handleChange}
        selector={selectPositionsOptions}
        action={fetchPositions}
      />
      <FilterItem
        filterKey={filterKey.field}
        value={filter.subjectarea_id}
        placeholder="Отрасль"
        onChange={handleChange}
        selector={selectFieldsOptions}
        action={fetchFields}
      />
    </FilterWrapper>
  );
}

export default React.memo(Filters);
