import { useCallback, useEffect, useState } from "react";
import { Box, BoxConfig } from "@ant-design/charts";
import { ChartSubtitle, ChartTitle, ChartWrapper } from "./Charts.styled";
import { StatisticAPI } from "../../services/statistics.service";
import { useFilters } from "../../hooks/filters.hooks";
import { StatisticDataBox } from "../../store/statistics/statistics.reducer";
import { useRegion } from "hooks/region.hooks";

interface DynamicSalariesProps {
  salaries: StatisticDataBox[];
  workType: string;
}

export const DynamicSalaries = ({
  salaries,
  workType,
}: DynamicSalariesProps) => {
  const config: BoxConfig = {
    data: salaries,
    height: 300,
    xField: "x",
    yField: ["low", "q1", "median", "q3", "high"],
  };

  return (
    <ChartWrapper>
      <ChartTitle>Динамика зарплат</ChartTitle>
      <ChartSubtitle>Зарплата {workType}, руб.</ChartSubtitle>
      <Box {...config} />
    </ChartWrapper>
  );
};

export default DynamicSalaries;
