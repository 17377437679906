import React, { useCallback, useEffect, useState } from "react";
import { DashboardWrapper } from "./Dashboard.styled";
import DynamicVacancies from "../Charts/DynamicVacancies";
import DynamicResumes from "components/Charts/DynamicResumes";
import DynamicSalaries from "../Charts/DynamicSalaries";
import SelectMapRegion from "./SelectMapRegion";
import { StatisticAPI } from "services/statistics.service";
import { StatisticParentDataBox } from "store/statistics/statistics.reducer";
import { useFilters } from "hooks/filters.hooks";
import { useRegion } from "hooks/region.hooks";

const Dashboard: React.FC = () => {
  const [filter] = useFilters();
  const region = useRegion();
  const [data, setData] = useState<StatisticParentDataBox>();

  const asyncFetch = useCallback(async () => {
    try {
      const response = await StatisticAPI.fetchSalaries({
        filters: filter,
        region_id: region?.res_id,
      });
      setData(response);
    } catch (error) {}
  }, [filter, region?.res_id]);

  useEffect(() => {
    asyncFetch();
  }, [asyncFetch]);

  return (
    <DashboardWrapper>
      <SelectMapRegion />
      <DynamicResumes />
      <DynamicVacancies />
      {data && (
        <>
          <DynamicSalaries
            salaries={data.fullday}
            workType={"за полный день или сменный график"}
          />
          <DynamicSalaries salaries={data.shift} workType={"за вахту"} />
          {/* <DynamicSalaries salaries={data.piecework} workType={"сдельная"} /> */}
        </>
      )}
    </DashboardWrapper>
  );
};

export default Dashboard;
