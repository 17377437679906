import Alert from "antd/lib/alert";
import React from "react";

const Sidebar: React.FC = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {/* <Alert
        message="Фильтры для графиков можно выбрать в выпадающих списках вверху страницы"
        description="График показывает историю изменения общего количества вакансий и текущий спрос на специалистов с учётом выбранных фильтров"
        type="info"
        showIcon
      />
      <br /> */}
      <Alert
        message="Показатели графика зарплат"
        description={
          <ul style={{ margin: 0 }}>
            <li>low - уровень зарплаты 10% наименее оплачиваемых вакансий</li>
            <li>q1 - уровень зарплаты 25% наименее оплачиваемых вакансий</li>
            <li>median - уровень зарплаты 50% вакансий</li>
            <li>q3 - уровень зарплаты 75% вакансий</li>
            <li>high - уровень зарплаты 90% вакансий</li>
            <br />
          </ul>
        }
        style={{ paddingBottom: 0 }}
        type="info"
        showIcon
      />
      <Alert
        message="Данные из источников Трудвсем и SuperJob"
        description={<></>}
        type="info"
        showIcon
      />
    </div>
  );
};

export default React.memo(Sidebar);
