import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import Spin from "antd/lib/spin";
import Layout from "antd/lib/layout";

import { fetchAllData } from "store/app/app.reducer";
import { selectLoading } from "store/app/app.selectors";

import Header from "components/Header/Header";
import Sidebar from "components/Sidebar/Sidebar";
import SplitScreen from "components/SplitScreen";
import Dashboard from "components/Dashboard/Dashboard";

function App() {
  const dispatch = useDispatch();
  const { loading, label } = useSelector(selectLoading);

  useEffect(() => {
    dispatch(fetchAllData());
  }, [dispatch]);

  return (
    <Spin tip={label} spinning={loading}>
      <Layout>
        <BrowserRouter>
          <Header />
          <SplitScreen>
            <Dashboard />
            <Sidebar />
          </SplitScreen>
        </BrowserRouter>
      </Layout>
    </Spin>
  );
}

export default React.memo(App);
