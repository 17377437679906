import axios from "axios";
import {
  StatisticDataBox,
  StatisticDataType,
  StatisticParentDataBox,
} from "../store/statistics/statistics.reducer";
import { IOptions } from "../types/NetworkState";
import { filterKey } from "../util/filterKeys";

type Parameter = "skills" | "subjectareas" | "specials";

const fetchByTop = async (parameter: Parameter, options: IOptions) => {
  const { filter, filters, limit, region_id } = options;

  let url = "/top";

  try {
    const response = await axios.post<StatisticDataType[]>(url, {
      parameter,
      orderby: filter,
      limit: limit || 10,
      region_id: region_id,
      [filterKey.skill]: filters[filterKey.skill],
      [filterKey.field]: filters[filterKey.field],
      [filterKey.position]: filters[filterKey.position],
    });
    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

export type StatisticVacanciesType = {
  filtered_vacancies?: number;
  no_salary_vacancies: number;
  vacancies: number;
  period: string;
};

export interface StatisticVacanciesParentType {
  resumecount: StatisticVacanciesType[];
  remote: StatisticVacanciesType[];
}

const fetchResumes = async (options: IOptions) => {
  try {
    const response = await axios.get<StatisticVacanciesParentType>(
      "/trends/vacancies",
      {
        params: {
          [filterKey.skill]: options.filters[filterKey.skill],
          [filterKey.field]: options.filters[filterKey.field],
          [filterKey.position]: options.filters[filterKey.position],
          region_id: options.region_id,
        },
      }
    );
    // return Array.isArray(response.data) ? response.data : [];
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchVacancies = async (options: IOptions) => {
  try {
    const response = await axios.get<StatisticVacanciesParentType>(
      "/trends/vacancies_vac",
      {
        params: {
          [filterKey.skill]: options.filters[filterKey.skill],
          [filterKey.field]: options.filters[filterKey.field],
          [filterKey.position]: options.filters[filterKey.position],
          region_id: options.region_id,
        },
      }
    );
    // return Array.isArray(response.data) ? response.data : [];
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

// /salaries_vac и /vacancies_vac

const fetchSalaries = async (options: IOptions) => {
  try {
    const response = await axios.get<StatisticParentDataBox>(
      "/trends/salaries",
      {
        params: {
          [filterKey.skill]: options.filters[filterKey.skill],
          [filterKey.field]: options.filters[filterKey.field],
          [filterKey.position]: options.filters[filterKey.position],
          region_id: options.region_id,
        },
      }
    );

    // return Array.isArray(response.data) ? response.data : [];
    return response.data;
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchByCountry = async (options: IOptions) => {
  try {
    const response = await axios.post<StatisticDataType[]>("/regionlist", {
      [filterKey.skill]: options.filters[filterKey.skill],
      [filterKey.field]: options.filters[filterKey.field],
      [filterKey.position]: options.filters[filterKey.position],
    });

    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

const fetchByRegion = async (options: IOptions) => {
  try {
    const response = await axios.post<StatisticDataType[]>("/regionstat", {
      region_id: options.region_id,
      [filterKey.skill]: options.filters[filterKey.skill],
      [filterKey.field]: options.filters[filterKey.field],
      [filterKey.position]: options.filters[filterKey.position],
    });

    return Array.isArray(response.data) ? response.data : [];
  } catch (error) {
    throw new Error("Ошибка при загрузке данных");
  }
};

export const StatisticAPI = {
  fetchSalaries,
  fetchResumes,
  fetchVacancies,
  fetchByCountry,
  fetchByRegion,
  fetchByTop,
};
